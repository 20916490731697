/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-unneeded-ternary */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/media-has-caption */
/* eslint-disable no-unused-vars */
import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import videojs from 'video.js';
import OneStudioSSAIAdsPlugin from '../../onestudio-ssai-ads/src/onestudio-ads';
import play from '../../assets/images/icons/play-img-new.png';
import pause from '../../assets/images/icons/pause.png';
import fastforward from '../../assets/images/icons/fast-forward.png';
import rewind from '../../assets/images/icons/rewind.png';
import { setUserVideoProgress } from '../../utils/localCache.util';
import { getAllDeviceInfo } from '../../utils/deviceInfo.util';
import { getVastUrl } from '../../services/channelData.service';
import closeIcon from '../../assets/images/icons/close.png';
import 'video.js/dist/video-js.css';
import { PLATFORMS } from '../../config/const.config';

const Player = ({ id, videoData, resumeFrom, handlePlayerClose }) => {
  const videoPlayerContainer = useRef();
  const playerIns = useRef();

  const playerObj = {
    played: false,
    videoUrl: '',
    vastUrl: '',
  };

  const isLive = false;
  const appstoreUrl = process.env.REACT_APP_FIRETV_APPSTORE_URL;
  const appName = process.env.REACT_APP_FIRETV_APP_NAME;
  const appBundleId = process.env.REACT_APP_FIRETV_APP_BUNDLE;
  const appChannelName = process.env.REACT_APP_FIRETV_CHANNEL_NAME;
  const contentSeries = '';
  const deviceInfo = getAllDeviceInfo();
  const videoId = videoData.id;
  const { title, season, episode, rating, channelId, duration } = videoData;
  const genres = videoData.genres || 'tvmovies';
  const liveStream = '';
  const producerName = '';
  const randomCB = Math.floor(Math.random() * 90000) + 10000;
  const [isPlaying, setIsPlaying] = useState(false);

  const timeFormat = (seconds) => {
    let secs = seconds;

    if (!secs && secs <= 0) {
      return '00:00:00';
    }

    let hours = null;
    if (secs / 3600 > 0) {
      hours = Math.floor(secs / 3600);
      secs -= hours * 3600;
    } else {
      hours = 0;
    }

    let mins = null;
    if (secs / 60 > 0) {
      mins = Math.floor(secs / 60);
      secs -= mins * 60;
    } else {
      mins = 0;
    }

    secs = Math.floor(secs);

    const hoursString = hours >= 10 ? String(hours) : `0${String(hours)}`;
    const minsString = mins >= 10 ? String(mins) : `0${String(mins)}`;
    const remainderString = secs >= 10 ? String(secs) : `0${String(secs)}`;

    return `${hoursString}:${minsString}:${remainderString}`;
  };

  const setupPlayer = () => {
    try {
      playerIns.current = videojs(videoPlayerContainer.current, {
        controls: true,
        autoplay: true,
        fluid: true,
        controlBar: {
          pictureInPictureToggle: false,
        },
        html5: {
          vhs: {
            experimentalBufferBasedABR: true,
          },
        },
        // plugins: {
        //   onestudioads: {
        //     type: 'vast',
        //     midrollInterval: 7,
        //     apsURL: playerObj.vastUrl,
        //     debug: true,
        //     adMarkers: PLATFORMS.WEB ? false : true,
        //   },
        // },
        poster: videoData.poster,
        sources: [
          {
            type: 'application/x-mpegurl',
            src: playerObj.videoUrl,
          },
        ],
        tracks: [
          {
            src: videoData.vttUrl,
            kind: 'subtitles',
            srclang: 'en',
            label: 'English',
          },
        ],
      });

      playerIns.current.on('adstart', (ad) => {
        window.document.getElementById('player-bottom-bar').style.display =
          'none';
      });
      playerIns.current.on('ads-ad-ended', () => {
        window.document.addEventListener(
          'keydown',
          (event) => {
            if (window.document.getElementById('player-bottom-bar')) {
              window.document.getElementById(
                'player-bottom-bar'
              ).style.display = 'block';
            }
            setTimeout(() => {
              if (window.document.getElementById('player-bottom-bar')) {
                window.document.getElementById(
                  'player-bottom-bar'
                ).style.display = 'none';
              }
            }, 7000);
            // }
          },
          false
        );
      });

      document.querySelectorAll('.`player-overlay`').forEach((el) =>
        el.addEventListener('click', (event) => {
          if (window.document.getElementsByClassName('vjs-control-bar')) {
            window.document.getElementsByClassName(
              'vjs-control-bar'
            ).style.display = 'block';
          }
          setTimeout(() => {
            // if (window.document.getElementsByClassName('vjs-control-bar')) {
            //   window.document.getElementsByClassName(
            //     'vjs-control-bar'
            //   ).style.display = 'none';
            // }
          }, 10000);
        })
      );

      playerIns.current.on('play', () => {
        if (resumeFrom > 0 && !playerObj.played) {
          playerObj.played = true;
          playerIns.current.currentTime(resumeFrom);
        }
      });

      playerIns.current.on('timeupdate', () => {
        const watchTime = Number(Math.floor(playerIns.current.currentTime()));
        if (watchTime === 0) return;

        if (watchTime % 5 === 0) {
          setUserVideoProgress(videoData.id, watchTime);
        }
      });

      playerIns.current.on('pause', () => {
        const watchTime = Math.floor(playerIns.current.currentTime());
        if (watchTime === 0) return;

        setUserVideoProgress(videoData.id, Number(watchTime));
      });
    } catch (e) {
      // ignore
    }
  };
  const clickOnBackButton = () => {
    handlePlayerClose();
  };

  const updateProgressBar = (current, total) =>
    (Math.floor(current) / Math.floor(total)) * 100;

  useEffect(() => {
    // videojs.registerPlugin('onestudioads', OneStudioSSAIAdsPlugin);
    let vastParams = process.env.REACT_APP_VAST_PARAMS;
    if (vastParams && vastParams.length) {
      
    
    vastParams = vastParams.replace('{content_type}', '');
    vastParams = vastParams.replace('{slot_type}', '');
    vastParams = vastParams.replace(
      '{device_ifa}',
      encodeURIComponent(deviceInfo.uid)
    );
    vastParams = vastParams.replace('{ua}', encodeURIComponent(deviceInfo.ua));
    vastParams = vastParams.replace('{os}', encodeURIComponent(deviceInfo.os));
    vastParams = vastParams.replace(
      '{osv}',
      encodeURIComponent(deviceInfo.osv)
    );
    vastParams = vastParams.replace(
      '{model}',
      encodeURIComponent(deviceInfo.model)
    );
    vastParams = vastParams.replace('{video_id}', videoId);
    vastParams = vastParams.replace(
      '{content_genre}',
      encodeURIComponent(genres)
    );
    vastParams = vastParams.replace(
      '{video_rating}',
      encodeURIComponent(rating)
    );
    vastParams = vastParams.replace('{content_duration}', duration);
    vastParams = vastParams.replace('{us_privacy}', '');
    vastParams = vastParams.replace(
      '{device_height}',
      deviceInfo.dimensions.height
    );
    vastParams = vastParams.replace(
      '{device_width}',
      deviceInfo.dimensions.width
    );
    vastParams = vastParams.replace(
      '{player_height}',
      deviceInfo.dimensions.height
    );
    vastParams = vastParams.replace(
      '{player_width}',
      deviceInfo.dimensions.width
    );
    vastParams = vastParams.replace('{dnt}', '');
    vastParams = vastParams.replace('{language}', 'en');
    vastParams = vastParams.replace('{connection_type}', '');
    vastParams = vastParams.replace('{category}', '');
    vastParams = vastParams.replace('{cb}', randomCB);
    vastParams = vastParams.replace('{content_episode}', episode);
    vastParams = vastParams.replace('{media_title}', encodeURIComponent(title));
    vastParams = vastParams.replace(
      '{series_title}',
      encodeURIComponent(contentSeries)
    );
    vastParams = vastParams.replace('{content_season}', season);
    vastParams += '&app_bundle=B08JPDLL1Z';
    vastParams += `&app_name=${encodeURIComponent(appChannelName)}`;
    vastParams += `&app_store_url=${encodeURIComponent(appstoreUrl)}`;
    }

    playerObj.videoUrl = videoData.hlsUrl;
    playerObj.vastUrl = `${process.env.REACT_APP_VAST_BASE_URL}?${vastParams}`;
    setupPlayer();

    setTimeout(() => {
      // Total Duration
      const totalTimeDuration = window.document.getElementById('total-time');

      playerIns.current.on('loadedmetadata', () => {
        const durations = timeFormat(
          Math.floor(playerObj.videoPlayer.duration())
        );
        if (totalTimeDuration > 0) {
          playerIns.current.currentTime(totalTimeDuration);
        }
        totalTimeDuration.innerText = durations;
      });

      const currentTimeDuration =
        window.document.getElementById('current-time');
      playerIns.current.on('timeupdate', () => {
        const currentDuration = timeFormat(
          Math.floor(playerIns.current.currentTime())
        );
        currentTimeDuration.innerText = currentDuration;

        // addLocalStorageData()
        // Update ProgressBar
        const playerProgressBarWidth =
          window.document.getElementById('player-progress');
        playerProgressBarWidth.style.width = `${updateProgressBar(
          playerIns.current.currentTime(),
          playerIns.current.duration()
        )}%`;
        const watchTime = Number(Math.floor(playerIns.current.currentTime()));
        if (watchTime === 0) return;

        if (watchTime % 5 === 0) {
          setUserVideoProgress(videoData.id, watchTime);
        }
      });

      playerIns.current.on('ended', () => {
        setIsPlaying(false);
        clickOnBackButton();
      });
    }, 1000);

    setTimeout(() => {
      if (window.document.getElementById('player-bottom-bar')) {
        window.document.getElementById('player-bottom-bar').style.display =
          'none';
      }
    }, 10000);

    return () => {
      if (playerIns.current) {
        setTimeout(() => {
          playerIns.current.dispose();
        }, 2000);
      }
    };
  }, []);

  return (
    <div className="video-container" id="video-container">
      <div id="video-player">
        <video
          id={id}
          className="video-js vjs-default-skin vjs-big-play-centered"
          width="auto"
          height="auto"
          controls="true"
          ref={videoPlayerContainer}
        />
      </div>
      <div className="player-overlay">
        <div className="player-bottom-bar" id="player-bottom-bar">
          <div className="player-text">
            <div className="player-watching-text">You&#39;re Watching</div>
            <div className="player-title">{title}</div>
          </div>
          <div className="player-progress-track">
            <div id="player-progress" className="player-progress-bar" />
          </div>
          <div className="player-timer">
            <div id="current-time" className="player-time">
              00:00:00
            </div>
            /
            <div id="total-time" className="player-time">
              00:00:00
            </div>
            <div className="player-button-group">
              <div
                className="rewind media-btn prj-element"
                id="rewind"
                data-focus-left="#rewind"
                data-focus-right="#play-pause"
                data-focus-up="#rewind"
                data-focus-down="#rewind"
                onClick={() => {
                  videojs(`player`).currentTime(
                    videojs(`player`).currentTime() - 10
                  );
                }}
                role="none"
              >
                <img src={rewind} alt="" className="media-btn-img" />
              </div>
              <div
                className="playpause media-btn prj-element"
                id="play-pause"
                data-focus-left="#rewind"
                data-focus-right="#fast-forward"
                data-focus-up="#play-pause"
                data-focus-down="#play-pause"
                role="none"
              >
                <img
                  src={isPlaying ? pause : play}
                  alt=""
                  className="media-btn-img"
                  onClick={() => {
                    if (videojs(`player`).paused()) {
                      setIsPlaying(true);
                      videojs(`player`).play();
                    } else {
                      setIsPlaying(false);
                      videojs(`player`).pause();
                    }
                  }}
                />
              </div>
              <div
                className="fastforward media-btn prj-element"
                id="fast-forward"
                data-focus-left="#play-pause"
                data-focus-right="#fast-forward"
                data-focus-up="#fast-forward"
                data-focus-down="#fast-forward"
                onClick={() => {
                  videojs(`player`).currentTime(
                    videojs(`player`).currentTime() + 10
                  );
                }}
                role="none"
              >
                <img src={fastforward} alt="" className="media-btn-img" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="close-video-container prj-element"
        id="back-to-page-video"
        onClick={() => clickOnBackButton()}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          height="28px"
          id="Layer_1"
          version="1.1"
          viewBox="0 0 512 512"
          width="28px"
          xmlSpace="preserve"
        >
          <path d="M443.6,387.1L312.4,255.4l131.5-130c5.4-5.4,5.4-14.2,0-19.6l-37.4-37.6c-2.6-2.6-6.1-4-9.8-4c-3.7,0-7.2,1.5-9.8,4  L256,197.8L124.9,68.3c-2.6-2.6-6.1-4-9.8-4c-3.7,0-7.2,1.5-9.8,4L68,105.9c-5.4,5.4-5.4,14.2,0,19.6l131.5,130L68.4,387.1  c-2.6,2.6-4.1,6.1-4.1,9.8c0,3.7,1.4,7.2,4.1,9.8l37.4,37.6c2.7,2.7,6.2,4.1,9.8,4.1c3.5,0,7.1-1.3,9.8-4.1L256,313.1l130.7,131.1  c2.7,2.7,6.2,4.1,9.8,4.1c3.5,0,7.1-1.3,9.8-4.1l37.4-37.6c2.6-2.6,4.1-6.1,4.1-9.8C447.7,393.2,446.2,389.7,443.6,387.1z" />
        </svg>
      </div>
    </div>
  );
};

Player.propTypes = {
  id: PropTypes.string.isRequired,
  videoData: PropTypes.shape({
    id: PropTypes.number,
    title: PropTypes.string,
    description: PropTypes.string,
    poster: PropTypes.string,
    posterH: PropTypes.string,
    posterV: PropTypes.string,
    hlsUrl: PropTypes.string,
    isPortrait: PropTypes.bool,
    genres: PropTypes.string,
    duration: PropTypes.number,
    category: PropTypes.string,
    channelId: PropTypes.number,
    director: PropTypes.string,
    actor1: PropTypes.string,
    actor2: PropTypes.string,
    actor3: PropTypes.string,
    rating: PropTypes.string,
    ratingSource: PropTypes.string,
    season: PropTypes.number,
    episode: PropTypes.number,
    srtUrl: PropTypes.string,
    vttUrl: PropTypes.string,
    source: PropTypes.string,
    playDirectUrl: PropTypes.string,
    liveVastUrl: PropTypes.string,
  }).isRequired,
  resumeFrom: PropTypes.number.isRequired,
  handlePlayerClose: PropTypes.func.isRequired,
};

export default Player;
