/* eslint-disable import/no-duplicates */
import landingImage from '../assets/images/disabilityownedwebsitestillsandscreenshot/shaking-hands-with-disabled-businessman-in-wheelchair-outdoor-in-SBV-337819973-HD0 (1).png';
// import sectionOneImage from '../assets/images/disabilityownedwebsitestillsandscreenshot/Biz-Ability 1280x720-4.png';
import sectionOneImage from '../assets/images/disabilityownedwebsitestillsandscreenshot/section_Imagery/Section 1-Hawaii Xtream sports.webp';
// import sectionTwoImage from '../assets/images/disabilityownedwebsitestillsandscreenshot/DMV BUSINESS SPOTLIGHT 1280X720.png';
import sectionTwoImage from '../assets/images/disabilityownedwebsitestillsandscreenshot/section_Imagery/Section 2 - Ocean Paddler.webp';
// import sectionThreeImage from '../assets/images/disabilityownedwebsitestillsandscreenshot/SMALL BUSINESS CAPITAL 1280X720.png';
import sectionThreeImage from '../assets/images/disabilityownedwebsitestillsandscreenshot/section_Imagery/Section 3 -Whats Sup.webp';
// import sectionFourImage from '../assets/images/disabilityownedwebsitestillsandscreenshot/DISABILITY OWNED CINEMA 1280X720.png';
import sectionFourImage from '../assets/images/disabilityownedwebsitestillsandscreenshot/section_Imagery/Section 4- inthezone.webp';
import sectionFiveImage from '../assets/images/disabilityownedwebsitestillsandscreenshot/SMALL BUSINESS CAPITAL 1280X720.png';
import sectionSixImage from '../assets/images/disabilityownedwebsitestillsandscreenshot/NDI FRC 1280x720-2.png';
import sectionSevenImage from '../assets/images/disabilityownedwebsitestillsandscreenshot/2GI 1280X720-2.png';
import sectionEightImage from '../assets/images/disabilityownedwebsitestillsandscreenshot/SMALL BUSINESS CAPITAL 1280X720.png';
import sectionNineImage from '../assets/images/disabilityownedwebsitestillsandscreenshot/NDI FRC 1280x720-2.png';
import sectionTenImage from '../assets/images/disabilityownedwebsitestillsandscreenshot/WEBINARS 1280X720.png';
import sectionElevenImage from '../assets/images/disabilityownedwebsitestillsandscreenshot/DISABILITY OWNED CINEMA 1280X720.png';

const LandingPageData = {
  topSection: {
    // heading: 'Disability Owned TV',
    heading: 'Adventure Zone',
    // sub_heading: 'Join today.',
    // description: 'The Business Channel for People with Disabilities',
    description: 'Your Ultimate Destination for Extreme Sports and Adventure',
    button_text: 'Watch Now',
    bg_img: landingImage,
  },
  section_one: {
    heading: 'Hawaiian Xtreme Sports',
    // heading: 'Nikki Powis Interviews Savvy Business Owners with Disabilities ',
    description: "If you want to live life on the edge, this program showcases the sports and lifestyle activities that push the limits. Focusing on the unique and extreme conditions found in Hawaii, Hawaiian Xtreme Sports TV is distinguished by the diversity of its segments, the caliber of the athletes featured and its cutting edge production angles and quality.",
    // description: 'Biz- Ability with Nikki Powis',
    image: sectionOneImage,
  },
  section_two: {
    heading:
      // 'DMV Spotlight Shines A Light on DMV Business Owners with Disabilities ',
      'Ocean Paddler',
    // description: 'DMV Spotlight ',
    description: `Working closely together with paddling's top athletes, Ocean Paddler focuses on lifestyle and event coverage. Ocean Paddler has gained a large and diverse following throughout the US and the world. The program has made ocean paddling a spectator sport. By incorporating the use of boats, helicopters, drones, POV combined with unique film angles from air, water and land, it allows view­ers to follow along as paddlers cross some of the most treacherous channels in the world.`,
    image: sectionTwoImage,
  },
  section_three: {
    heading:
      // 'US SBA Videos provides information to plan, start, and grow a business.',
      'What SUP',
    // description: 'US Small Business Administration ',
    description: "Stand up and get ready for 'What SUP ', the ultimate Stand-Up Paddleboarding adventure series. From serene paddles in tranquil waters to intense SUP racing, this series covers it all. Tap 'Play Video' to glide into the exciting world of SUP in Hawaii's picturesque waters.",
    image: sectionThreeImage,
  },
  section_four: {
    heading:
      // 'National Disabilitiy Institute focuses on the financial health and well-being of people with disabilities.',
      'In The Zone',
    description:
    //   'Send children on adventures with their favourite characters in a space made just for them—free with your membership.',
      `'In The Zone' is the ultimate Waterman series. Focused heavily on the best ocean and action sports from Hawaii and also around the world. This series is created by Zone Media, the world's leaders in content production from Hawaii. Big wave surfing, SUP, outrigger, windsurfing, kite­boarding, canoe surfing, tandem and much more.`,
    image: sectionFourImage,
  },
  // section_five: {
  //   heading: `Small Business Start Ups Features SBA Funded Success Stories`,
  //   // description:
  //   //   'Send children on adventures with their favourite characters in a space made just for them—free with your membership.',
  //   image: sectionFiveImage,
  // },
  // section_six: {
  //   heading: `Get Money Matters Insights From NDI's  Financial Resilience Center `,
  //   // description:
  //   //   'Send children on adventures with their favourite characters in a space made just for them—free with your membership.',
  //   image: sectionSixImage,
  // },
  // section_seven: {
  //   heading: `Meet 2Gether International's Founders with Disabilities`,
  //   // description:
  //   //   'Send children on adventures with their favourite characters in a space made just for them—free with your membership.',
  //   image: sectionSevenImage,
  // },
  // section_eight: {
  //   heading: 'Small Business Captial - Tips On How To Fund Your Business',
  //   // description:
  //   //   'Send children on adventures with their favourite characters in a space made just for them—free with your membership.',
  //   image: sectionEightImage,
  // },
  // section_nine: {
  //   heading: `Get Money Matters Insights From NDI's...`,
  //   // description:
  //   //   'Send children on adventures with their favourite characters in a space made just for them—free with your membership.',
  //   image: sectionNineImage,
  // },
  // section_ten: {
  //   heading: `Watch NDI's 2022 Small Busines Confernece Sessions `,
  //   // description:
  //   //   'Send children on adventures with their favourite characters in a space made just for them—free with your membership.',
  //   image: sectionTenImage,
  // },
  // section_eleven: {
  //   heading: 'Disability Owned Cinema features Empowering Films and Docs',
  //   // description:
  //   //   'Send children on adventures with their favourite characters in a space made just for them—free with your membership.',
  //   image: sectionElevenImage,
  // },
  faq: {
    main_title: 'Frequently Asked Questions',
    question_answer: [
      // {
      //   question: 'What is Disabilty Owned?',
      //   answer:
      //     'Disability Owned is the FREE business education and information streaming service for people with disabilities.  Watch original shows, educational videos, and inspiring profiles, that can help people with disabilities plan, start, and grow a business enterprise. ',
      // },
      {
        question: 'What is Adventure Zone?',
        answer:
          `Adventure Zone is a premier streaming service dedicated to showcasing the best of extreme sports and adventurous lifestyle in Hawaii. We feature a wide range of content, including TV series like "Hawaiian Xtreme Sports," "RESET: Hawai'i," "Ocean Paddler," "What SUP," and "In The Zone," all highlighting various aspects of Hawaii's unique and thrilling outdoor activities.`,
      },
      // {
      //   question: 'How do I get started?',
      //   answer:
      //     'Watch Disability Owned for FREE on the web and on your Roku, Apple TV, Amazon Fire TV, iOS and Android devices. ',
      // },
      {
        question: 'How do I access Adventure Zone?',
        answer:
          `You can easily access Adventure Zone for FREE on multiple platforms including the web, Roku, Apple TV, Amazon Fire TV, and mobile devices running iOS or Android. Simply download the Adventure Zone app on your preferred device, or visit our website to start streaming.`
      },
      // {
      //   question: 'Where can I watch?',
      //   answer:
      //     'Disability Owned is FREE to watch on your laptop,Roku TV, Apple TV, Amazon Fire TV, iOS and Android device. ',
      // },
      {
        question: 'Where can I watch Adventure Zone?',
        answer:
          `Adventure Zone is available for free streaming on various devices. You can enjoy our exciting content on your laptop, Roku TV, Apple TV, Amazon Fire TV, or through our mobile app available on both iOS and Android devices. Wherever you are, Adventure Zone's thrilling content is just a click away!`,
      },
      // {
      //   question: 'What can I watch on Disability Owned?',
      //   answer:
      //     'Disability Owned has an orginal shows, educational video, and inspiring profiles, and more. Watch for FREE on anytime on your favorite streaming device.',
      // },
      {
        question: 'What can I watch on Adventure Zone?',
        answer:
          `On Adventure Zone, you can watch a wide array of original shows, documentaries, and exclusive live events. Our content ranges from extreme sports like surfing, stand-up paddleboarding, and rock climbing to cultural explorations and athlete profiles. With our diverse offerings, there’s something for every adventure enthusiast.`,
      },
      // {
      //   question: 'Is Disability Owned Good For Everyone ',
      //   answer:
      //     'Disability Owned offer business education, information, and inspiration everyone can enjoy, even those without a disability or interest in being self-employed.',
      // },
      {
        question: 'Is Adventure Zone suitable for everyone?',
        answer:
          `Absolutely! Adventure Zone is designed for a wide audience, offering content that appeals not just to extreme sports enthusiasts, but also to those interested in learning about Hawaii's rich culture and stunning landscapes. Our programs provide an exciting, educational, and inspiring experience for everyone, regardless of whether they participate in extreme sports or not.`,
      },
    ],
  },
  about_us: {
    heading: 'About Us',
    // description: `Disability Owned is a FREE business streaming service for people with disabilities. Watch Disability Owned is available to watch on your laptop, Roku TV, Apple TV, Amazon Fire TV, iOS or Android devices. Boston Media, LLC creators and managers of Disability Owned, is a member of the National Disability Institute's Community Navigator Pilot Program (CNPP). NDI's CNPP is funded in part through a grant from the U.S. Small Business Administrtion.`,
    description: `Adventure Zone is your gateway to the exhilarating world of Hawaiian extreme sports and outdoor adventures. We are a premier streaming service that brings Hawaii's most thrilling activities and stunning landscapes directly to you. Our channel features a diverse lineup of original series, including "Hawaiian Xtreme Sports," "RESET: Hawai'i," "Ocean Paddler," "What SUP," and "In The Zone."`,
    mission:`Our mission is to showcase the beauty, culture, and excitement of Hawaii's outdoor sports scene. Adventure Zone is available for free on web, Roku, Apple TV, Amazon Fire TV, and mobile devices, making it easy for viewers worldwide to access our content.`,
    experience:`With a team of experienced professionals in media production and adventure sports, we aim to inspire, educate, and entertain our audience with high-quality, engaging content. Whether you're an adventure enthusiast or just love to explore new cultures and landscapes, Adventure Zone has something for everyone. Join us and dive into the adventure!`,
  },
  copyRight: 'Copyright © 2023 All rights reserved',
};

export default LandingPageData;
