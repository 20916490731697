
/* eslint-disable consistent-return */
/* eslint-disable react/no-unknown-property */
/* eslint-disable import/no-unresolved */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Scrollbar, A11y } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/scrollbar';
import videojs from 'video.js';
import DetailLayout from '../../layout/Detail';
import Player from '../common/Player';
import { getUserVideoProgress } from '../../utils/localCache.util';
import convertSecToTime from '../../utils/timeformat.util';
import playIcon from '../../assets/images/icons/play.png';
import resumeIcon from '../../assets/images/icons/resume.png';
import restoreFocus from '../../utils/restoreFocus';
import scrollAppView from '../../utils/viewScroll.util';

const DetailPage = ({
  detailPageData,
  handleHideDetailPage,
  videos,
  containerIdPrefix,
  setShowDetailPage,
}) => {
  const [showPlayer, setShowPlayer] = useState(false);
  const [showButtons, setShowButtons] = useState(false);
  const [currentProgress, setCurrentProgress] = useState(0);
  // eslint-disable-next-line no-unused-vars
  const [showPlayerFromProgress, setShowPlayerFromProgress] = useState(false);
  const [activeSwiperSlide, setActiveSwiperSlide] = useState(7);

  const scrollHandleButtonId = `scroll-element-${containerIdPrefix}`;
  const moreLikeThis = videos.filter((a) => a.title !== detailPageData.title);
  window.scrollTo(0, 0);
  const [calculatedWidth, setCalculatedWidth] = useState(0);
  const parentRef = useRef(null)

  useEffect(() => {
    const updateWidth = () => {
      if (parentRef.current) {
        const height = parentRef.current.offsetHeight;
        console.log("height", height)
        console.log("width", parentRef.current.offsetWidth)
        const aspectRatio = 16 / 9;
        const width = document.getElementsByClassName("swiper-slide")[0].style.width      ;
        setCalculatedWidth(width);
      }
    };
  
    window.addEventListener('resize', updateWidth);
    updateWidth(); // Initial calculation
  
    //return () => window.removeEventListener('resize', updateWidth);
  }, []);

  useEffect(() => {
    try {
      if (window.document.getElementById('bg-video-player')) {
        videojs('bg-player').pause();
      }
    } catch (e) {
      // do nothing
    }
  }, []);

  useEffect(() => {
    const myProgress = getUserVideoProgress(detailPageData.id);
    if (myProgress > 0) {
      setCurrentProgress(Math.ceil(Number(myProgress)));
    } else {
      setCurrentProgress(0);
    }
    setShowButtons(true);
  }, [detailPageData.id]);

  const handlePlayerOpen = (fromProgress) => {
    setShowPlayerFromProgress(fromProgress);
    setShowPlayer(true);
  };
  const handlePlayerClose = () => {
    setShowPlayer(false);
    const myProgress = getUserVideoProgress(detailPageData.id);
    if (myProgress > 0) {
      setCurrentProgress(Math.ceil(Number(myProgress)));
    } else {
      setCurrentProgress(0);
    }
    setShowDetailPage(true);
  };

  const handleSwitchDetailPage = (id) => {
    const element = window.document.querySelectorAll(
      `#${containerIdPrefix}${id}`
    );
    if (element.length > 0) {
      element[0].click();
    }
  };

  const goBack = () => {
    try {
      if (window.document.getElementById('bg-video-player')) {
        videojs('bg-player').play();
      }
    } catch (e) {
      // do nothing
    }
    if (!showPlayer) {
      handleHideDetailPage();
      // setTimeout(() => {
      //   restoreFocus();
      // }, 1000);
    }
  };

  const handleScroll = () => {
    const focusedElements = window.document.querySelectorAll(
      '.prj-element.focused'
    );

    if (focusedElements.length > 0) {
      if (focusedElements[0].id === 'resume-btn') {
        // window.document.querySelectorAll(
        //   '.video-details'
        // )[0].style.marginTop = `788px`;
      } else if (detailPageData.isPortrait === true) {
        window.document.querySelectorAll(
          '.video-details'
        )[0].style.marginTop = `195px`;
      } else if (detailPageData.isPortrait === false) {
        window.document.querySelectorAll(
          '.video-details'
        )[0].style.marginTop = `239px`;
      }
    }
  };

  const handleScrollEle = () => {
    const focusedElements = window.document.querySelectorAll(
      '.prj-element.focused'
    );

    if (focusedElements.length > 0) {
      scrollAppView(focusedElements[0]);
      window.document.querySelectorAll(
        '.video-details'
      )[0].style.marginTop = `-190px`;
    }
  };

  const fallbackImage = (error, titleName) => {
    const err = { ...error };
    const errorElement = window.document.getElementById(err.target.id);
    if (errorElement !== null) {
      errorElement.outerHTML = `<div class="error-text-img"><div class="error-title">${titleName}</div></div>`;
    }
    return err;
  };

  const toHoursAndMinutes = (totalSeconds) => {
    const totalMinutes = Math.floor(totalSeconds / 60);

    const seconds = totalSeconds % 60;
    const hours = Math.floor(totalMinutes / 60);
    const minutes = totalMinutes % 60;

    if (hours === 0) {
      if (minutes === 0) {
        return `${seconds}s`;
      }
      return `${minutes}m ${seconds}s`;
    }

    return `${hours}h ${minutes}m ${seconds}s`;
  };

  return (
    <DetailLayout>
      <>
        <div
          className="bg-poster"
          style={{ backgroundImage: `url("${detailPageData.posterH}")` }}
        />
        <button
          type="button"
          className="hide"
          id="video-detail-focus"
          onClick={handleScroll}
        >
          Scroll
        </button>
        <div className="bg-poster-layer" />

        <div
          className="back-to-page prj-element"
          id="back-to-page"
          onClick={goBack}
          aria-hidden
        >
          {/* &lt; &nbsp; */}
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="#ffff"
            height="40px"
            width="40px"
            version="1.1"
            id="Layer_1"
            viewBox="0 0 330 330"
            xmlSpace="preserve"
          >
            <path
              id="XMLID_6_"
              d="M165,0C74.019,0,0,74.019,0,165s74.019,165,165,165s165-74.019,165-165S255.981,0,165,0z M205.606,234.394  c5.858,5.857,5.858,15.355,0,21.213C202.678,258.535,198.839,260,195,260s-7.678-1.464-10.606-4.394l-80-79.998  c-2.813-2.813-4.394-6.628-4.394-10.606c0-3.978,1.58-7.794,4.394-10.607l80-80.002c5.857-5.858,15.355-5.858,21.213,0  c5.858,5.857,5.858,15.355,0,21.213l-69.393,69.396L205.606,234.394z"
            />
          </svg>
        </div>
        <div
          className={
            detailPageData.isPortrait
              ? 'video-details-main-div movies'
              : 'video-details-main-div videos'
          }
          id="video-detail-focus"
        >
          <div className="video-details">
            <div className="details">
              <div className="title">{detailPageData.title}</div>
              {detailPageData.releaseDate && (
                <span className="video-details-release-date">
                  {detailPageData.releaseDate.split('-')[0]}
                </span>
              )}
              {detailPageData.duration && (
                <>
                  {detailPageData.releaseDate && <span>&bull;</span>}
                  <span className="video-details-duration">
                    {toHoursAndMinutes(Number(detailPageData.duration))}
                  </span>
                </>
              )}

              {detailPageData.rating ? <span>&bull;</span> : ''}
              {detailPageData.rating && (
                <div className="video-rating">
                  <span>{detailPageData.rating}</span>
                </div>
              )}
              <div className="description">{detailPageData.description}</div>
              <div className="actor-section">
                {detailPageData.actor1 && (
                  <span className="detail-page-actor1">
                    {detailPageData.actor1}
                  </span>
                )}
                {detailPageData.actor2 && (
                  <>
                    {detailPageData.actor1 && (
                      <span className="detail-page-vertical-line">&#124;</span>
                    )}
                    <span className="detail-page-actor2">
                      {detailPageData.actor2}
                    </span>
                  </>
                )}
                {detailPageData.actor3 && (
                  <>
                    {detailPageData.actor1 || detailPageData.actor2 ? (
                      <span className="detail-page-vertical-line">&#124;</span>
                    ) : (
                      ''
                    )}
                    <span className="detail-page-actor3">
                      {detailPageData.actor3}
                    </span>
                  </>
                )}
                {detailPageData.director && (
                  <>
                    {detailPageData.actor1 ||
                    detailPageData.actor2 ||
                    detailPageData.actor3 ? (
                      <span>&bull;</span>
                    ) : (
                      ''
                    )}
                    {detailPageData.director && (
                      <div className="video-rating">
                        <span>{detailPageData.director}</span>
                      </div>
                    )}
                  </>
                )}
              </div>
              {showButtons && (
                <div className="buttons">
                  {currentProgress !== 0 && (
                    <button
                      id="resume-btn"
                      type="button"
                      className="play-btn prj-element"
                      data-focus-left={false}
                      data-focus-right="#play-btn"
                      data-focus-up={false}
                      data-focus-down=".video.prj-element"
                      data-on-self-focus="#video-detail-focus"
                      onClick={() => {
                        handlePlayerOpen(true);
                      }}
                    >
                      <img src={resumeIcon} alt="resume" />
                      Resume from {toHoursAndMinutes(currentProgress)}
                    </button>
                  )}
                  <button
                    id="play-btn"
                    type="button"
                    className={
                      currentProgress === 0
                        ? 'play-btn small prj-element '
                        : 'play-btn prj-element beginning-btn '
                    }
                    data-focus-left="#resume-btn"
                    data-focus-right={false}
                    data-focus-up={false}
                    data-focus-down=".video.prj-element"
                    data-on-self-focus="#video-detail-focus"
                    onClick={() => {
                      handlePlayerOpen(false);
                    }}
                  >
                    <img src={playIcon} alt="play" />
                    {currentProgress === 0 ? 'Play' : 'Play from beginning'}
                  </button>
                </div>
              )}
            </div>
            <div className="image">
              <div
                className={`image-wrap ${
                  detailPageData.isPortrait ? 'portrait' : 'landscape'
                }`}
              >
                <img
                  src={
                    detailPageData.isPortrait
                      ? detailPageData.posterV
                      : detailPageData.posterH
                  }
                  alt={detailPageData.title}
                />
              </div>
            </div>
          </div>

          {videos.length > 1 && (
            <div className="more-like-this">
              <div className=""><h2  style={{color: "white", fontWeight:"bold", marginLeft:"40px"}}>More Like This</h2></div>
              <button
                type="button"
                className="hide"
                id={scrollHandleButtonId}
                onClick={handleScrollEle}
              >
                Scroll
              </button>
              <div className="horizontal-list more-like-this-horizontal-list">
                
                <Swiper
                  className="mySwiper"
                  modules={[Navigation, Pagination, Scrollbar, A11y]}
                  slidesPerGroup={
                    detailPageData.isPortrait ? activeSwiperSlide : 4
                  }
                  navigation
                  breakpoints={{
                    320: {
                      slidesPerView: detailPageData.isPortrait ? 2 : 2,
                      spaceBetween: 4,
                    },
                    576: {
                      slidesPerView: detailPageData.isPortrait ? 2 : 2,
                      spaceBetween: 10,
                    },
                    640: {
                      slidesPerView: detailPageData.isPortrait ? 2 : 1,
                      spaceBetween: 20,
                    },
                    768: {
                      slidesPerView: detailPageData.isPortrait ? 4 : 3,
                      spaceBetween: 40,
                    },
                    1024: {
                      slidesPerView: detailPageData.isPortrait ? 6 : 4,
                      spaceBetween: 20,
                    },
                  }}
                  id={
                    detailPageData.isPortrait
                      ? 'more-like-this-videos'
                      : 'more-like-this-movies'
                  }
                  watchSlidesProgress
                >
                  <div className="list">
                    <div className="list-container more-like-this-list-container">
                      {moreLikeThis.map((v, idx) => (
                        <SwiperSlide id={`SwiperSlide${idx}v`}>
                          <div
                           ref={parentRef} style={{ width: `${calculatedWidth}` }}
                            className={`video prj-element media-element ${
                              detailPageData.isPortrait
                                ? 'portrait'
                                : 'landscape'
                            }`}
                            id={`detailitem${idx}v`}
                            key={`more-like-this-${v.id}`}
                            data-focus-left={
                              idx === 0 ? '' : `#detailitem${idx - 1}v`
                            }
                            data-focus-right={
                              idx + 1 === videos.length
                                ? ''
                                : `#detailitem${idx + 1}v`
                            }
                            data-focus-up="#play-btn"
                            // data-focus-down=".video.prj-element"
                            data-on-self-focus={`#${scrollHandleButtonId}`}
                            // data-on-self-focus="#video-detail-focus"
                            onClick={() => handleSwitchDetailPage(v.id)}
                            role="none"
                          >
                            <div className="img" style={{ width: `${calculatedWidth}` }}>
                              <div className="img-container">
                                <img
                                  id={`target--detailimage-h-v-${v.id}`}
                                  src={
                                    detailPageData.isPortrait
                                      ? v.posterV
                                      : v.posterH
                                  }
                                  alt={v.title}
                                  onError={(error) =>
                                    fallbackImage(error, v.title)
                                  }
                                />
                              </div>
                              <p className="title detail-page-title">
                                {v.title}
                              </p>
                              {/* <p className="sub-title">{v.description}</p> */}
                              {/* {type === VIDEO_TYPES.VIDEO && (
          <p className="sub-title">{description}</p>
        )} */}
                            </div>
                          </div>
                        </SwiperSlide>
                      ))}
                    </div>
                  </div>
                </Swiper>
              </div>
            </div>
          )}
        </div>
        {showPlayer && (
          <Player
            id="player"
            videoData={detailPageData}
            resumeFrom={showPlayerFromProgress ? currentProgress : 0}
            handlePlayerClose={handlePlayerClose}
            setShowDetailPage={setShowDetailPage}
          />
        )}
      </>
    </DetailLayout>
  );
};

DetailPage.propTypes = {
  detailPageData: PropTypes.shape({
    id: PropTypes.number,
    title: PropTypes.string,
    description: PropTypes.string,
    poster: PropTypes.string,
    posterH: PropTypes.string,
    posterV: PropTypes.string,
    hlsUrl: PropTypes.string,
    isPortrait: PropTypes.bool,
    genres: PropTypes.string,
    duration: PropTypes.number,
    category: PropTypes.string,
    channelId: PropTypes.number,
    director: PropTypes.string,
    actor1: PropTypes.string,
    actor2: PropTypes.string,
    actor3: PropTypes.string,
    rating: PropTypes.string,
    releaseDate: PropTypes.string,
    ratingSource: PropTypes.string,
    season: PropTypes.number,
    episode: PropTypes.number,
    srtUrl: PropTypes.string,
    vttUrl: PropTypes.string,
    source: PropTypes.string,
    playDirectUrl: PropTypes.string,
    liveVastUrl: PropTypes.string,
  }).isRequired,
  handleHideDetailPage: PropTypes.func.isRequired,
  setShowDetailPage: PropTypes.func.isRequired,
  videos: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      title: PropTypes.string,
      description: PropTypes.string,
      shortDescription: PropTypes.string,
      hlsUrl: PropTypes.string,
      poster: PropTypes.string,
      posterH: PropTypes.string,
      posterV: PropTypes.string,
      startTime: PropTypes.string,
      endTime: PropTypes.string,
      duration: PropTypes.number,
      type: PropTypes.string,
      genres: PropTypes.string,
      category: PropTypes.string,
      channelId: PropTypes.number,
      director: PropTypes.string,
      actor1: PropTypes.string,
      actor2: PropTypes.string,
      actor3: PropTypes.string,
      rating: PropTypes.string,
      releaseDate: PropTypes.string,
      ratingSource: PropTypes.string,
      season: PropTypes.number,
      episode: PropTypes.number,
      srtUrl: PropTypes.string,
      vttUrl: PropTypes.string,
      source: PropTypes.string,
      playDirectUrl: PropTypes.string,
      liveVastUrl: PropTypes.string,
    })
  ).isRequired,
  containerIdPrefix: PropTypes.string.isRequired,
};

export default DetailPage;
